.btn {
  text-transform: capitalize !important;
  box-shadow: none;
  &-principal {
    font-size: 0.9rem !important;
  }
}


.btn-secundario {
  color: #4a91e3 !important;
  border-color: #4a91e3 !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  font-size: 0.9rem !important;
  width: 160px;
}

.btn-outline-default:hover {
  border-color: #4a91e3 !important;
  color: #4a91e3 !important;
}

.btn-primario {
  color: #fff !important;
  border-color: #4a91e3 !important;
  background-color: #4a91e3 !important;
  padding-top: 0.6rem !important;
  padding-bottom: 0.6rem !important;
  font-size: 0.9rem !important;
  width: 160px;
  &.btn-mis-match{
    padding-top: 0.3rem !important;
    padding-bottom: 0.3rem !important;
    font-size: 0.7rem !important;
    width: 130px;
    padding: 0.84rem 2rem;
  }
}

.btn-primario-modal {
  &-md {
    color: #fff !important;
    border-color: #4a91e3 !important;
    background-color: #4a91e3 !important;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    font-size: 0.8rem !important;
  }
  &-sm {
    color: #fff !important;
    border-color: #4a91e3 !important;
    background-color: #4a91e3 !important;
    padding: 0.3rem 1.4rem;
    font-size: 0.8rem !important;
    width: 120px;
    height: 2rem;
  }
}

.btn-secundario-modal {
  &-md {
    color: #4a91e3 !important;
    border-color: #4a91e3 !important;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    font-size: 0.8rem !important;
  }
  &-sm {
    color: #4a91e3 !important;
    border-color: #4a91e3 !important;
    padding: 0.3rem 1.4rem !important;;
    font-size: 0.8rem !important;
    width: 120px;
    height: 2rem;
  }
}

.btn-ingresar-movil {
  width: 7rem !important;
  margin-top: 0 !important;
}

.btn-descubre-match {
  color: #fff !important;
  border-color: #E71F36 !important;
  background-color: #E71F36 !important;
  padding-top: 0.4rem !important;
  padding-bottom: 0.4rem !important;
  font-size: 1.3rem !important;
  width: 200px;
}

.btn-responder {
  color: #fff !important;
  border-color: #4a91e3 !important;
  background-color: #4a91e3 !important;
  padding-top: 0.4rem !important;
  padding-bottom: 0.4rem !important;
  font-size: 0.7rem !important;
} 