/*
    @define estilo de modales para responsive
*/
@media (max-width: 1024px) {
    .sbj_has-modal {
        .sbj_modal-container {
            .sbj_modal {
                .sbj_modal-content {
                    &>header {
                        button.btn-close {
                            top: -14px;
                            right: 0px;
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;
                        }
                    }
                    top: 10%;
                    width: 100%!important;
                    max-width: 100%!important;
                    @include keyframes(sbj_insert-modal) {
                        0% {
                            top: -10%;
                        }
                        50% {
                            top: 15%;
                        }
                        100% {
                            top: 10%;
                        }
                    };
                    @include keyframes(sbj_remove-modal) {
                        0% {
                            top: 10%;
                        }
                        50% {
                            top: 15%;
                            opacity: 1;
                        }
                        100% {
                            top: 0%;
                            opacity: 0;
                        }
                    };
                }
            }
        }
    }
}