.descripcionDestacado {
    font-family: "Roboto";
    font-size: 14px;
    color: #36474F;
    font-weight: normal;
}

.text-azul {
    color: #0F69B4;
}

.titulo{
    color: #36474F;
    font-weight: 700 !important;
    text-align: left;
    padding-left: 15px;
    margin-bottom: 25px;
}

.fondo-Destacado {
    display: flex;
    // margin-top: 70px;
    background-color: #E8EFF5;
}

.tituloDestacado {
    font-family: $font-family-serif;
    font-size: 17px;
    color: $primary;
}

.fotoCardDestacados {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 150px;
}

.buttonDestacados {
    background: -moz-linear-gradient(top, #ffffff 5%, #ffffff 100%);
    background: -webkit-linear-gradient(top, #ffffff 5%, #ffffff 100%);
    background: -o-linear-gradient(top, #ffffff 5%, #ffffff 100%);
    background: -ms-linear-gradient(top, #ffffff 5%, #ffffff 100%);
    background: linear-gradient(to bottom, #ffffff 5%, #ffffff 100%);
    filter: unquote("progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#1e62d0',GradientType=0)");
    background-color: #ffffff;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    border-radius: 100px;
    border: 1px solid #4A91E3;
    font-family: 'Roboto' !important;
    display: inline-block;
    cursor: pointer;
    color: #4A91E3;
    font-size: 12px !important;
    padding: 5px 18px 3px 18px !important;
    text-decoration: none;
    margin-left: 60%;
    text-transform: none;
}

.btnDestacado-match {
    margin-left: 0% !important;
}

.buttonDestacados:hover {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0.05, #4A91E3), color-stop(1, #3d94f6));
    background: -moz-linear-gradient(top, #4A91E3 5%, #3d94f6 100%);
    background: -webkit-linear-gradient(top, #4A91E3 5%, #3d94f6 100%);
    background: -o-linear-gradient(top, #4A91E3 5%, #3d94f6 100%);
    background: -ms-linear-gradient(top, #4A91E3 5%, #3d94f6 100%);
    background: linear-gradient(to bottom, #4A91E3 5%, #3d94f6 100%);
    filter: unquote("progid: DXImageTransform.Microsoft.gradient(startColorstr='#4A91E3', endColorstr='#3d94f6', GradientType=0)");
    background-color: #4A91E3;
}

.buttonDestacados:active {
    position: relative;
    top: 1px;
}