body {
    .loading {
        position: fixed;
        z-index: 9999999;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        overflow: hidden;
        background-color: #fff;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 150px;
        background-image: $logo;
    }
}