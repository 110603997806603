html {
  height: 100%;
  box-sizing: border-box;
  font-size: 100%;
  body {
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    background-color: $white;
    position: relative;
    margin: 0;
    height: inherit;
    main {
      .row {
        margin: 0;
      }
      position: relative;
      min-height: inherit;
      padding-bottom: 120px;
      header {
        color: $black;
      }
    }
  }
}
