.mensajeria {
  min-height: 30rem;
  margin-top: -1.5rem !important;
  margin-left: -1rem !important;
  margin-right: -0.9rem !important;
  background-color: ghostwhite;
  overflow-x: hidden;
  .listado-mensajes {
    background-color: #fff;
    min-height: 30rem;
    padding-left: 0;
    padding-right: 0;
    .mensaje {
      border-bottom: 1px solid #dfdfdf;
      cursor: pointer;
      .avatarBandeja{
        margin: 10px;
      }
      .avatar {
        border-radius: 25px;
        height: 50px;
        width: 50px;
      }
      .titulo-msje {
        color: #4a91e3;
        padding-left: 0; 
      }
      .titulo-Bandeja {
        color: #000000;
        padding-left: 0;
        font-size: 14px;
        width: 100%;
        font-weight: bold;
      }
      .detalle {
        font-size: 12px;
        margin-bottom: 0px;
        color: #666;
      }
      .fecha {
        font-size: 9px;
        margin-bottom: 0px;
        color: #666;
      }
      .pendiente {
        background-color: rgb(173, 10, 10);
        border-radius: 5px;
        font-size: 10px;
        width: 5px;
        margin-bottom: 0px;
        color: #FFF;
      }
    }
    .mensaje:hover {
      background-color: #bcd7f5;
    }
    .mensajeBandeja {
      border-bottom: 1px solid #dfdfdf;
      .avatar {
        border-radius: 25px;
        height: 50px;
        width: 50px;
      }
      .titulo-msje {
        color: #4a91e3;
        padding-left: 0; 
      }
      .titulo-Bandeja {
        color: #000000;
        padding-left: 0;
        font-size: 14px;
        width: 100%;
        font-weight: bold;
      }
      .detalle {
        font-size: 12px;
        margin-bottom: 0px;
        color: #666;
      }
      .fecha {
        font-size: 9px;
        margin-bottom: 0px;
        color: #666;
      }
    }
    .activo {
      background-color: ghostwhite;
    }
  }
  .conversacion {
    background-color: ghostwhite;
    padding-left: 0;
    padding-right: 0;
    height: 70vh;
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
    .usuarioSesion {
      &.ingreso {
        margin-top: 30px;
        right: 10px;
        position: absolute;
        width: 10rem;
        font-size: 0.9rem;
      }
      &.avatarNombre {
        right: 10px;
        position: absolute;
        font-family: Roboto;
        font-size: 18px;
        color: $primary;
      }
      &.fotoUsuarioEnSesion {
        margin: auto;
        margin-top: 10%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 60px;
        width: 60px;
        cursor: pointer;
        &.avatar {
          text-align: right;
          height: 120px;
          width: 120px;
          margin-top: -20px;
          margin-bottom: 10px;
        }
      }
      span {
        white-space: nowrap;
      }
    }
    .panel {
      padding: 1rem;
      background-color: #fff;
      border-radius: 5px;
      .titulo-msje {
        color: #4a91e3;
        padding-left: 0;
        font-size: 14px;
        width: 100%;
      }
      .titulo-Bandeja {
        color: #000000;
        padding-left: 0;
        font-size: 14px;
        width: 100%;
        font-weight: bold;
      }
      .detalle {
        font-size: 14px;
        margin-bottom: 2px;
        color: #666;
      }
    }
    .background-owner {
      background-color:#E6F3FF;
    }
  }
  .responder {
    position: absolute;
    bottom: 0;
    padding-bottom: 10px;
    width: 100%;
    @media screen and (min-width: 1px) and (max-width: 396px) {
      position: relative;
      padding-bottom: 100px;
    }

  }
}
