//custom sass para modales
.sbj_has-modal {
  .sbj_modal-container {
    z-index: 1;
    .sbj_modal-backdrop {
      background-color: transparentize($black, 0.5);
    }
    .sbj_modal {
      &.sbj_modal-alert {
        .sbj_modal-content {
          width: 30%;
          max-width: 30%;
        }
      }
      .sbj_modal-content {
        & > header {
          .tab-buttons {
            button:first-child {
              border-top-left-radius: 10px;
            }
            button:last-child {
              border-top-right-radius: 10px;
            }
          }
          button.btn-close {
            z-index: 1;
            position: absolute;
            background-color: $gray-200;
            border: 0;
            border-radius: 50%;
            padding: 2px 8px;
            top: -8px;
            right: -8px;
            box-shadow: 0px 1px 3px 0px transparentize($black, 0.9);
            color: $primary;
            font-size: 1.2em;
          }
        }
        top: 10%;
        padding: 0;
        &.small {
          width: 400px;
          max-width: 400px;
        }
        @include keyframes(sbj_insert-modal) {
          0% {
            top: -10%;
          }
          50% {
            top: 15%;
          }
          100% {
            top: 10%;
          }
        }
        @include keyframes(sbj_remove-modal) {
          0% {
            top: 10%;
          }
          50% {
            top: 15%;
            opacity: 1;
          }
          100% {
            top: 0%;
            opacity: 0;
          }
        }
        & > section {
          padding: 20px;
        }
        & > footer {
          background-color: $white;
        }
      }
    }
  }
}

.modal-header {
    padding: 0.8rem 2rem 0.5rem 2rem;
    background-color: #f5f5f5;
    justify-content: center !important;
    .modal-title {
      font-size: 1.3rem;
      color: $color-primary;
      font-weight: 400;
      padding-left: 10px;
    }
    .icono-active {
      color: $color-primary;
      font-size: 1.5em;
    }
    .close {
      font-size: 2rem;
      color: $color-primary;
      font-weight: bold;
      opacity: unset;
      padding-top: 0.7rem;
    }
  }
  .modal-header-error {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
  }
  .modal-header-success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
  }
  .modal-body {
    padding: 0.5rem;
    h5 {
      color: $color-primary;
      padding-bottom: 0.5rem;
      font-size: 16px;
      font-weight: 500;
    }
    .nombre {
      font-weight: 500;
      font-size: 14px;
    }
    .valor {
      font-size: 14px;
    }
  }
  .sbj_has-modal .sbj_modal-container .sbj_modal .sbj_modal-content > footer {
    border-bottom-right-radius: 0.3rem !important;
    border-bottom-left-radius: 0.3rem;
  }
  .modal-footer-alert {
    padding: 0.5rem 2rem 0.5rem 2rem;
    float: right;
  }
  .modal-footer-success {
    padding: 0.5rem 2rem 0.5rem 2rem;
    float: right;
  }
  .modal-footer {
    border-top: none;
    padding: 0rem 0rem 1rem 0rem;
  }
  .vdivider {
    border-left: 1px solid #dfdfdf;
  }
