body {
  min-height: 100vh;
}

.container-fluid {
  padding: 0;
}

.navbar {
  background-color: white;
}

.nav-link.active {
  text-decoration: underline;
}

.iconsRrss img {
  height: 40px;
  width: 40px;

}

.sub_text{
  font-size: 13px;
  color:#fff;
  height: 0;
}

.iconsRrss {
  display: inline-block;
  padding: 10px;
}

.cardDestacado {
  width: 270px;
}

.help {
  width: 65px;
  height: 65px;
  position: fixed;
  top: 45%;
  right: 45px;
  z-index: 1;
  cursor: pointer;
  animation-delay: 1.5s;
}


.turismo_atiende {
  width: 243px;
  height: 320px;
  position: absolute;
  border-radius: 10px;
  top: 110%;
  right: -65%;
  z-index: 999999;
  background: #2C2E41;
  animation-delay: 0s;
  padding: 16px;
  display: none;
}

@media screen and (max-width: 600px) {
  .turismo_atiende {

    top: -100%;
    right: -15%;

  }
}

@media screen and (max-width: 425px){
  .turismo_atiende {
      top: -200%;
      right: 45%;
  }
}


@media screen and (max-width: 375px){
  .turismo_atiende {
      top: -200%;
      right: 16%;
  }
}

  @media screen and (max-width: 360px){
    .turismo_atiende {
        top: -200%;
        right: 10%;
    }
  }

    @media screen and (max-width: 320px){
      .turismo_atiende {
          right: -20%;
      }
}

.turismo_atiende h2 {
  font-family: Roboto;
  font-weight: 700;
  width: 100%;
  text-align: center;
  color: #FFF;
  font-size: 18px;
  line-height: 20px;
}


.turismo_atiende p {
  width: 100%;
  margin-top: 20px;
  color: #E2E2E2;


  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  



}

.turismo_atiende .cta-a {
  width: 100%;
  height: 36px;
  line-height: 31px;
  background: #4A90E3;
  margin-top: 6px;
  border-radius: 50px;

  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;

}
.turismo_atiende .cta-b {
  width: 100%;
  height: 36px;
  line-height: 31px;
  background: #4A90E3;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 50px;
}
.turismo_atiende .cta-c {
  width: 100%;
  height: 36px;
  line-height: 31px;
  background: #00D14B;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  border-radius: 50px;
}

.turismo_atiende .info {
  width: 30px;
  height: 30px;
  background: #FF0000;
  position: absolute;
  top: -5px;
  left: -10px;
  cursor: pointer;
  border-radius: 50px;

}


.turismo_atiende a {
  width: 100%;
  text-align: center;
  display: block;
  color: #fff;
}

.cerrar-img{
  border-radius: 50px;
  padding-left: 2px;
}
.sombra {
  box-shadow: -8px 6px 10px 0px;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}






.fotoCardTestimonios {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 180px;
  width: 180px;

  @media only screen and (max-width: 600px) {
  }

  @media only screen and (min-width: 600px) {
  }

  @media only screen and (min-width: 768px) {
  }

  @media only screen and (min-width: 992px) {
  }

  @media only screen and (min-width: 1200px) {
  }

  @media only screen and (min-width: 1400px) {
  }
}

.card-container {
  height: 370px;
}

.custom-config-dropdown {
  .dropdown-content {
    display: initial;
  }
}

.btn-primary {
  background-color: $primary;
}

.button-link {
  background: none;
  border: 0;
  color: $blue-accent-4;
  &:hover {
    text-decoration: underline;
  }
}

.registro-usuario {
  .terminos-custom {
    label {
      margin-right: $mr-0;
    }
  }
}

.text-primary {
  color: $primary;
}
a:hover {
  text-decoration: underline;
}

.card-body {
  padding: 0.75rem 1.25rem 1.25rem 1.25rem;
}

input,
input::-webkit-input-placeholder {
  font-size: 12px;
}

.form-check {
  padding-left: 0;
}

.titulo-empresa-detalle {
  font-family: Roboto;
  font-size: 24px;
  color: #36474f;
  font-weight: bold;
}

.empresa-detalle-datos {
  display: inline-block;
  text-align: center
}

.empresa-informacion {
  font-family: Roboto;
  font-size: 14px;
  color: #36474f;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.empresa-descripcion-col {
  padding: 0 0px;
}

.descripcion-empresa-detalle {
  font-family: Roboto;
  font-size: 17px;
  color: #36474f;
}

.empresa-categorias {
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  &__list {
    overflow: hidden;
    width: 100%;
  }
}

.titulo-item{
  font-weight: bold;
}

.ico-categorias {
  color: #4a91e3;
}

.dropdown-toggle::after {
  color: #4a91e3 !important;
}

.navbar {
  padding: 0 !important;
  background-color: white !important;
  color: #4a91e3;
  box-shadow: none;
  font-family: Arial, Helvetica, sans-serif;
  a {
    span {
      color: #4a91e3 !important;
    }
    &:hover {
      color: #4a91e3 !important;
    }
  }

  .dropdown {
    padding: 0;
    .dropdown-menu {
      padding: 0;
      a {
        padding: 10px 30px;
      }
      .dropdown-item:hover {
        background-color: #c4e0fe !important;
        color: #36474f !important;
      }
    }
  }
}

// Select Multiple
.css-12jo7m5 {
  background-color: #4a91e3;
  color: #fff !important;
}
.css-1alnv5e {
  cursor: pointer;
}
//

.lbl-obligatorio {
  color: red;
  vertical-align: text-top;
}

.modal-contactar-titulo {
  font-size: 1.3rem !important;
}

.modal-contactar-descripcion {
  color: #999999;
}

.modal-confirmacion-titulo {
  color: #4a91e3;
  font-size: 24px;
}

.modal-confirmacion-descripcion {
  color: #36474f;
}

.modal-favorito-titulo-agregado {
  font-size: 16px !important;
  text-align: center !important;
  color: #4a91e3;
}

.modal-favorito-titulo-quitado {
  font-size: 16px !important;
  text-align: center !important;
  color: #666;
}

.text-cargando-imagen {
  color: red;
}

.link {
  &:hover {
    text-decoration: none;
  }
}

.texto-imagen-temp {
  color: #e04143;
  font-size: 14px;
}

.img-impresa {
  object-fit: scale-down;
  border: 1px solid #f8f8f8;
  background-color: #f8f8f8;
  cursor: pointer;
  &-destacada {
    border: 2px dotted #4a91e3 !important;
    object-fit: scale-down;
    padding: 5px;
    cursor: not-allowed;
  }
}

.badge-categoria {
  background-color: #2b2d42 !important;
}

// Slider Destacados

.alice-carousel__dots-item {
  display: inline-block;
  width: 18px;
  height: 18px;
  cursor: pointer;
  border-radius: 50%;
  background-color: #4285f4;
  opacity: 0.5;
}

.alice-carousel__dots-item:hover,
.alice-carousel__dots-item.__active {
  background-color: #4285f4;
  opacity: 1;
}

// Mis Match
.container-mis-match {
  @media (min-width: 1200px) {
    max-width: 1000px;
  }
  @media (min-width: 1400px) {
    max-width: 1140px;
  }
}

.switch label input[type=checkbox]:checked + .lever {
  background-color: #4a91e3;
}
.switch label input[type=checkbox]:checked + .lever:after {
  background-color: #fff;
  left: 1.5rem;
}