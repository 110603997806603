.navbar {
    width: 100%;
    margin-top: 1%;
    @media screen and (min-width: 1px) and (max-width: 766px) {
        position: fixed;
        z-index: 2;
        transition: top 0.5s;
        &.top {
            bottom: 0px;
        }
        &.normal {
            bottom: 0px;
        }
    }
}