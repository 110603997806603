.fileupload {
    border: 1px dashed #ccc;
    width: 100%;
    &.drag-over {
        border: 2px dashed #090;
    }
    &.uploading {
        .fileupload-progress {
            div {
                transition: width 0.5s;
                -webkit-transition: width 0.5s;
            }
        }
    }
    input[type="file"] {
        display: none;
    }
    .fileupload-progress {
        background: #fafafa;
        height: 10px;
        div {
            transition: none;
            -webkit-transition: none;
            width: 0%;
            height: 100%;
            background-image: -webkit-linear-gradient(
                    -45deg,
                    transparent 33%,
                    rgba(0, 0, 0, 0.1) 33%,
                    rgba(0, 0, 0, 0.1) 66%,
                    transparent 66%
                ),
                -webkit-linear-gradient(top, rgba(255, 255, 255, 0.25), rgba(0, 0, 0, 0.25)),
                -webkit-linear-gradient(left, #98da98, #5bea5b);
            background-size: 35px 20px, 100% 100%, 100% 100%;
        }
    }
    .fileupload-footer {
        padding: 10px;
        text-align: center;
        label {
            display: block;
            margin: 0;
            padding-top: 5px;
        }
        button {
            border: none;
            border-radius: 10px;
            background-color: $unique-color !important;
            color: $white;
            font-size: 0.8em;
            padding: 5px 15px;
            &:hover {
                background-color: $primary;
            }
        }
    }
}
