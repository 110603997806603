/*
    define estilos especificos para el modal
    de registro o inicio de sesión
*/
.tab-buttons button {
    font-size: 1.1em !important;
}

.no-active {
    background-color: #C4E0FE !important;
    color: #36474F !important;
}

.tab-buttons button:not(.active):hover {
    color: #fff !important; 
}