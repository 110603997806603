$color-primary: #4a91e3;

// Typography
//
// Font, line-height, and color for body text, headings, and more.
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,400i,700&display=swap');
// stylelint-disable value-keyword-case
$font-family-sans-serif:      'Roboto', sans-serif !default;
$font-family-serif:           'Roboto Slab', serif !default;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:            $font-family-sans-serif !default;
// stylelint-enable value-keyword-case