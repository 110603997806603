@import "carousel";

.carousel-custom {
  margin-top: 122px;
  h1 {
    padding: 22px;
    @media (max-width: 1260px) {
      font-size: 27px;
    }
    @media (max-width: 530px) {
      font-size: 17px;
    }
  }
  h3 {
    margin-top: 20% !important;
    padding-left: 10%;
    padding-right: 10%;
    @media (max-width: 1260px) {
      font-size: 25px;
      padding-left: 1%;
    }
    @media (max-width: 530px) {
      font-size: 15px;
    }
  }
  position: relative;
  button.descripcion {
    background-color: transparent;
    -moz-border-radius: 42px;
    -webkit-border-radius: 42px;
    border-radius: 42px;
    border: 2px solid #ffffff;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Roboto;
    font-size: 18px;
    padding: 6px 40px;
    text-decoration: none;
    text-align: center;
    margin: auto;
    min-width: 10rem;
    &:hover {
      background-color: white;
      color: black;
    }

    @media (max-width: 1260px) {
      padding: 6px 40px;
      font-size: 17px;
      border: 2px solid #ffffff;
    }
    @media (max-width: 530px) {
      font-size: 0.7rem;
      padding: 6px 10px;
    }
    @media (max-width: 360px) {
      padding: 3px;
      border: 2px solid #ffffff;
    }

  }

  .slide {
    background-color: white !important;
    overflow: hidden;
    max-height: 530px;
  }
}

.testimonial-carousel .testimonial {
  margin-left: 0;
  margin-right: 0;
}
.carousel .carousel-multi-item .testimonial-carousel {
  padding-left: 4%;
}
.titulo-testimonio {
  color: #4a91e3;
  font-size: 20px;
  font-family: Roboto Slab;
  margin-bottom: 0px;
}
.nombre-creador-testimonio {
  color: #36474f;
  font-weight: bold;
  font-size: 16px;
  font-family: Roboto;
}
.descripcion-testimonio {
  font-family: Roboto;
  font-size: 14px;
  color: #36474f;
  text-align: center;
}
