div.pagination {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    div {
        margin-right: 2px;
        margin-left: 2px;
        select {
            display: block!important;
        }
        input {
            width: 3em;
        }
        button {
            width: 3em;
            &.active {
                background: #AAA;
            }
        }
    }
}