.descubreMatch { 
  padding: 2rem;
  margin-top: 3.5rem !important;

  .infografiaMatch{
    padding: 2rem;
    height: 60rem;
  }

  .titulo-descubre-match {
    color: #36474f;
    font-size: 2rem;
    font-family: $font-family-sans-serif;
    font-weight: 600;
  }

  .descripcion-descubre-match {
    color: #36474f;
    text-align: center;
    font-size: 0.9rem;
  }

  .steps-form .steps-row .steps-step .btn-circle {
    color: $color-primary;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    margin-top: -5px;
    font-size: 1.2rem;
  }

  .steps-row {
    justify-content: center !important;
    display: flex !important;
  }
  .steps-row:before {
    width: 8.25rem !important;
  }
  .steps-form .steps-row .steps-step p {
    margin-top: 0.5rem;
    font-size: 0.9rem;
    margin-bottom: 0;
  }

  .steps-step {
    width: 180px;
    .btn-step {
      background-color: $color-primary;
      color: white !important;
      &:hover {
        background-color: $color-primary;
        color: white;
      }
    }
    .btn-disable {
      border: 1px solid $color-primary;
      color: $color-primary;
      box-shadow: none;
      &:hover {
        background-color: transparent;
        box-shadow: none;
        cursor: initial;
      }
    }
  }

  .lbl-group-match {
    color: #36474f;
    margin-bottom: 0;
    font-size: 14px;
  }
}

.wizardMatch {
  .step-txt-state-on {
    font-weight: bold;
    color: $color-primary;
  }
  .step-txt-state-off {
    font-weight: bold;
    color: #676767;
  }

  .steps-row {
    justify-content: center !important;
    display: flex !important;
  }
  .steps-row:before {
    margin-top: 10px;
    width: 7.5rem !important;
    background-color: $color-primary;
  }

  .steps-form .steps-row .steps-step .btn-circle {
    color: $color-primary;
    width: 60px;
    height: 60px;
    border-radius: 45px;
    margin-top: -5px;
    font-size: 1.2rem;
  }

  .steps-step {
    width: 180px !important;
    .btn-step {
      border: 1px solid $color-primary;
      color: white !important;
      background-color: $color-primary;
      &:hover {
        color: white !important;
      }
    }
    .btn-disable {
      background-color: transparent;
      border: 1px solid $color-primary;
      &:hover {
        color: white;
      }
    }
  }
  p {
    text-align: center;
    color: #36474f;
  }
  h3 {
    color: $color-primary;
    text-align: center;
  }
}

.titulo-sin-resultados {
  color: $color-primary;
  font-size: 20px;
  font-family: $font-family-sans-serif;
  font-weight: 600;
}

.lbl-rating {
  color: #3c763d;
  vertical-align: sub;
  margin-bottom: 0px;
  font-weight: bold;
}

.match-sin-resultados {
  min-height: 400px;
}

.descripcion-match {
  color: #36474f;
  font-size: 0.9rem !important;
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
}
