.tab-buttons {
    display: flex;
    flex-direction: row;
    background-color: $white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    button {
        font-size: 1.5em;
        color: $white;
        border: 0;
        padding: 15px;
        flex: 1;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        &:not(.active):hover {
            background-color: $primary;
        }
        &.active {
            color: $primary;
            background-color: transparent;
            font-weight: bold;
            &:hover {
                background-color: $white;
            }
            &+ :not(.active) {
                border-bottom-right-radius: 0;
                &~* {
                    border-bottom-right-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
        }
        &:first-child {
            border-bottom-left-radius: 0;
        }
    }
}