.registro {
    .tituloRegistro {
        font-family: Roboto;
        font-size: 20px;
        color: #0F69B4;
        &.sub {
            margin-top: 50px;
        }
    }
    .campos {
        font-family: Roboto;
        font-size: 14px;
        color: #4A4A4A;
        width: 359px;
        height: 43px;
        margin-top: 10px;
        margin-bottom: -20px;
        &.sub {
            margin-top: 1px;
            margin-bottom: 10px;
        }
    }
    input[type=checkbox],
    input[type=password],
    input[type=email],
    input[type=text] {
        font-family: Roboto;
        font-size: 14px;
        border: 1px solid #4A4A4A;
        border-radius: 8px;
        width: 359px;
        border-radius: 8px;
    }
}