.footer {
  padding: 30px;
  background-color: #2b2d42;
  position: absolute;
  bottom: 0;
  width: 100%;
  .container-fluid {
    margin-top: 0px;
  }
}

.chattigo-widget-trigger__logo {
  margin-bottom: 160px;
}
