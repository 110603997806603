.dashboardContainer {
  padding-top: 3%;
  @media screen and (min-width: 1px) and (max-width: 396px) {
    padding-top: 10%;
  }
  &.menu {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
  background-color: #E8EFF5;
}

.body-dashboard {
  background-color: #fff;
}

.titulo-dashboard {
  font-weight: 700 !important;
  font-size: 1.3rem;
  color: #36474f;
  font-family: Roboto;
}

.margen-superior-dashboard {
    margin-top: 34px !important;
}
.lbl-nombre-usuario {
  color: #4A91E3;
  text-transform: capitalize;
  font-size: 18px;
}

.list-group {
  z-index: 0;
  .list-group-item {
    border: none;
    cursor: pointer;
    background-color: #E8EFF5;
  }
  .list-group-item.active {
      color: #4A91E3 !important;
      background-color: #fff !important;
      border-left: 5px solid #4A91E3;
      z-index: 0;
  }
}

.ico-camera {
  color: #4A91E3;
  cursor: pointer;
}

.fondo-mis-match {
   padding-bottom: 8rem;
}

.fondo-favoritos {
  padding-bottom: 8rem;
}

.margin-dashboard {
  margin-bottom: 10rem;
}

.list-group {
  font-size: 14px;
}